import {Component, Input, OnInit} from '@angular/core';
import { UserService } from "../../services/user.service";
import {ConfigurationService} from '../../services/configuration.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'cp-purchase-recap',
  templateUrl: './purchase-recap.component.html',
  styleUrls: [],
})
export class PurchaseRecapComponent implements OnInit {

  @Input() public isError: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public isFreeTrial: boolean = false;
  @Input() public showHomeLink: boolean = true;
  @Input() public hasAmnestyToForgive: boolean = false;
  @Input() public isIntentConfirmationRegistrationFailed: boolean = false;
  public customerServiceEmail: string = environment.customer_service.email;
  public customerServicePhone: string = environment.customer_service.phone;
  public customerServiceActiveTimeDescription = '';

  constructor(
    private userService: UserService,
    private configurationService: ConfigurationService,
  ) {
  }

  ngOnInit() {
    this.configurationService.subscribeConfiguration().subscribe(config => {
       this.customerServiceActiveTimeDescription = config.customer_service_active_time;
    });
    if (!this.isError) {
      this.userService.clearPayload();
    }
  }

}
