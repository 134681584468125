<ng-container [ngSwitch]="step">
  <cp-waiting *ngSwitchCase="0"></cp-waiting>
  <ng-container *ngSwitchCase="1">
    <section class="mt-3 mt-lg-10 mb-5 overflow-hidden">
      <div class="container text-center">
        <h1 class="text-secondary">TI ASPETTIAMO PER LA TUA PROVA GRATUITA!</h1>
        <h4 class="text-primary">Ricordati di portare:</h4>
        <div class="d-flex text-primary">
          <ul class="mx-auto list-color list-color--secondary">
            <li class="">Scarpe da ginnastica pulite</li>
            <li class="">Carta di identità o Patente</li>
            <li class="">Asciugamano</li>
          </ul>
        </div>

        <div class="container mt-5">
          <dl class="row bg-primary mb-0 p-3 py-md-5 rounded text-white mx-n5 mx-sm-n10 mx-lg-n11">
            <dt class="col-12 text-secondary mb-2">
              <h4>RIEPILOGO DELLA PRENOTAZIONE</h4>
            </dt>
            <dt class="col-6 col-sm-3 col-lg-2 text-right">NOMINATIVO</dt>
            <dl class="col-6 col-sm-3 col-lg-2 text-left">{{ trial?.user?.name }} {{ trial?.user?.surname }}</dl>
            <dt class="col-6 col-sm-2 col-lg-2 text-right">EMAIL</dt>
            <dl class="col-6 col-sm-4 col-lg-2 text-left">{{ trial?.user?.email }}</dl>
            <dt class="col-6 col-sm-3 col-lg-2 text-right">TELEFONO</dt>
            <dl class="col-6 col-sm-3 col-lg-2 text-left">
              {{ trial?.user?.cel }}
            </dl>
            <dt class="col-6 col-sm-2 col-lg-2 text-right">DATA/ORA</dt>
            <dl class="col-6 col-sm-4 col-lg-2 text-left">{{ dateString }} {{ timeString }}</dl>
            <dt class="col-6 col-sm-3 col-lg-2 text-right">PALESTRA</dt>
            <dl class="col-6 col-sm-3 col-lg-2 text-left">{{ trial?.gym?.name }}</dl>
            <dt class="col-6 col-sm-2 col-lg-2 text-right">TIPOLOGIA</dt>
            <dl class="col-6 col-sm-4 col-lg-2 text-left">
              {{ trial?.fitness_type === "gym" ? "Palestra" : " Digital Training" }}
            </dl>
            <dl class="mb-0 col-12 mt-1" *ngIf="canUpdate">
              <button class="btn btn-outline-secondary-text-white" (click)="nextStep('next')">
                Modifica la prenotazione</button
              ><br />
            </dl>
          </dl>
        </div>
        <div class="text-center mt-5">
          Non vuoi aspettare? <br />
          <a class="btn btn-outline-secondary-text-primary mt-2" [routerLink]="'/subscription'">
            Acquista un abbonamento ORA!
          </a>
        </div>
        <ngb-alert [type]="'primary'" [dismissible]="false" class="mt-5">
          <small>
            Per qualsiasi domanda scrivici a
            <a href="mailto:{{ customerServiceEmail }}" class="text-secondary">{{ customerServiceEmail }}</a> e ti
            risponderemo al più presto
            <br />
            Oppure chiamaci allo <a href="tel:{{ customerServicePhoneLink }}" class="text-secondary">{{ customerServicePhone }}</a> {{ customerServiceActiveTimeDescription }}
          </small>
        </ngb-alert>
      </div>
    </section>
  </ng-container>
  <ng-container *ngSwitchCase="2"
    ><cp-free-trial-editor (done)="nextStep($event)" [freeTrial]="trial"></cp-free-trial-editor
  ></ng-container>
  <ng-container *ngSwitchCase="3">
    <cp-free-trial-recap></cp-free-trial-recap>
  </ng-container>
  <cp-unexpected-error *ngSwitchCase="4" [showHomeLink]="true"></cp-unexpected-error>
</ng-container>
