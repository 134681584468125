<section class="mt-3 mt-lg-10 mb-5">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center" *ngIf="isError">
        <h1 class="text-secondary">SI E' VERIFICATO UN ERRORE</h1>
        <h4 class="text-primary">L'operazione non è andata a buon fine, ti chiediamo di riprovare.</h4>
      </div>
      <div class="col-12 text-center" *ngIf="!isError">
        <div *ngIf="isFreeTrial">
          <h1 class="text-secondary">TI ASPETTIAMO PER LA TUA PROVA GRATUITA!</h1>
          <h4 class="text-primary">
            Controlla la tua casella di posta, ti invieremo la conferma della tua prenotazione.
          </h4>
          <h4 class="text-primary mt-2">Ricordati di portare:</h4>
          <div class="d-flex">
            <ul class="mx-auto list-color list-color--secondary">
              <li class="text-primary">Scarpe da ginnastica pulite</li>
              <li class="text-primary">Carta di identità o Patente</li>
              <li class="text-primary">Asciugamano</li>
            </ul>
          </div>
        </div>
        <div *ngIf="!isFreeTrial">
          <h1 class="text-secondary">GRAZIE, BENVENUTO IN WEBFIT!</h1>
          <h4 class="text-primary">
            Controlla la tua casella di posta, ti invieremo la conferma di acquisto con i termini di utilizzo.
          </h4>
          <ngb-alert [dismissible]="false" [type]="'primary'" class="my-3" *ngIf="hasAmnestyToForgive">
            <h6 class="text-secondary">Attenzione!</h6>
            Non appena riceveremo la conferma dei pagamenti ti invieremo una conferma di attivazione dell'abbonamento e
            condono della sanatoria.
            <br />
            Fino ad allora, l'abbonamento resterà disattivato, e la sanatoria risulterà ancora da pagare.
          </ngb-alert>
          <ngb-alert [dismissible]="false" [type]="'primary'" class="my-3"
                     *ngIf="isIntentConfirmationRegistrationFailed">
            <h6 class="text-secondary">Attenzione!</h6>
            La richiesta di abbonamento è stata presa in carico, tuttavia potrebbe essere necessario qualche altro
            minuto.
            <br/>
            Nel frattempo, per via di un malfunzionamento riscontrato durante il tuo processo di acquisto, ti preghiamo
            di non ritentare di abbonarti.
            <br/>
            Se l'abbonamento da te acquistato non dovesse comparire nel giro di un giorno, ti invitiamo a contattare il
            servizio clienti.
            <br/>
            Ci scusiamo per il disagio.
          </ngb-alert>

        </div>
        <h6 class="text-primary mt-2">
          Per qualsiasi domanda scrivici a {{ customerServiceEmail }} e ti risponderemo al più presto
          <br />
          Oppure chiamaci allo {{ customerServicePhone }} {{ customerServiceActiveTimeDescription }}
        </h6>
        <div class="text-center mt-3" *ngIf="showHomeLink">
          <a class="btn btn-outline-secondary-text-primary" [routerLink]="['/home']">Vai alla dashboard</a>
        </div>
      </div>
    </div>
  </div>
</section>
