import { Component, OnInit } from '@angular/core';
import { AutoLogoutService } from 'src/app/features/logged-in/services/autologout.service';
import { FreeTrialService } from '../../services/free-trial.service';
import { FreeTrialModel } from '../../../../models/free-trial.model';
import { UserService } from '../../../../../../shared/services/user.service';
import * as moment from 'moment';
import {ConfigurationService} from '../../../../../../shared/services/configuration.service';
import {environment} from '../../../../../../../environments/environment';

@Component({
    selector: 'cp-free-trial-main',
    templateUrl: './free-trial-main.component.html',
    styleUrls: ['./free-trial-main.component.scss'],
})
export class FreeTrialMainComponent implements OnInit {
    public step: number = 0;
    public trial: FreeTrialModel;
    public dateString: string;
    public timeString: string;
    public canUpdate: boolean;
    public customerServiceEmail: string = environment.customer_service.email;
    public customerServicePhone: string = environment.customer_service.phone;
    public customerServicePhoneLink: string = environment.customer_service.phone_for_link;
    public customerServiceActiveTimeDescription = '';

    constructor(
        private readonly userService: UserService,
        private readonly purchaseTrialService: FreeTrialService,
        private readonly autoLogoutService: AutoLogoutService,
        private readonly configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        this.configurationService.subscribeConfiguration().subscribe(config => {
            this.customerServiceActiveTimeDescription = config.customer_service_active_time;
        });
        this.purchaseTrialService.getUserTrial().subscribe(
            trial => {
                if (trial) {
                    this.userService.subscribe().subscribe(user => {
                        if (user.trial_status.can_book_trial) {
                            this.step = 2;
                        } else {
                            let trialDate = moment(trial.date);
                            this.trial = trial;
                            this.dateString = trialDate.format('DD/MM/YYYY');
                            this.timeString = trialDate.format('HH:mm');
                            this.canUpdate = user.trial_status.can_update_trial;
                            this.step = 1;
                        }
                    })
                } else {
                    this.step = 2;
                }
            },
            ex => {
                this.step = 4;
            }
        );

        this.autoLogoutService.resetIdle();
    }

    nextStep(event: string | any) {
        switch (event) {
            case 'next':
                if(this.step < 3 && !(this.step == 2 && this.trial)) {
                    this.step++;
                    break;
                }

            case 'restart':
                this.step = 0;
                this.ngOnInit();
                break;

            default:
                this.step = 4;
        }
    }
}
