import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {Moment} from 'moment';
import { InvoiceModel } from '../models/invoices.model';
import { UserServiceObject } from '../models/user.model';

@Injectable()
export class HelperService {

  constructor(private router: Router) {}

  //#region Dates
  public getDateForPicker(date: Moment): NgbDate {
    return new NgbDate(
      date.year(),
      date.month() + 1,
      date.date()
    );
  }

  public convertDateFromPicker(date: NgbDate): Moment {
    let format = {
      minimumIntegerDigits: 2,
      useGrouping: false
    };
    return moment(`${date.year}-${date.month.toLocaleString('en-US', format)}-${date.day.toLocaleString('en-US', format)}T00:00:00Z`);
  }

  /**
   * Converts YYYY-MM-DD hh:mm:ss to moment without timezone conversions (sets timezone at +1h without changing date or hour)
   * @param date
   * @param tz
   */
  public stringToMomentAtTZ(date: string, tz = "+01:00"): Moment {
    return moment(date + tz);
  }
  //#endregion Dates

  //#region user services
  guessUserServiceEndDateForCheckout(userSubscription: UserServiceObject, checkoutDate : moment.Moment = null){
    let sub_end_date : string;
    switch(userSubscription.sub_info.subscription_category){
      case "standard":
          // FIXME: Wrong if checkout is late.
          sub_end_date = moment(userSubscription.sub_end_date).format("DD/MM/YYYY");
          break;
      case "flex":
          let next_extension_date = moment(userSubscription.sub_end_date).add(1, 'day').startOf('day');
          if(next_extension_date.diff(checkoutDate.startOf('day'), 'days') >= 15){
              sub_end_date = moment(userSubscription.sub_end_date).format("DD/MM/YYYY");
          } else {
              sub_end_date = moment(userSubscription.sub_end_date).add(userSubscription.sub_info.month_duration, 'months').format("DD/MM/YYYY")
          }
    }
    return sub_end_date;
  }
  //#endregion user services

  //#region invoices
  // FIXME: the guessing should probably be done in InvoiceTransformer, or even better
  //        we should make sure that Agon knows exactly what payments are included in which invoice
  //        All invoices manually created by Agon should have a metadata.type value
  //        For the invoices coming via stripe we should use billing_reason + strp_lines
  guessInvoiceDescription(invoice:InvoiceModel) {
    let baseType;
    switch (invoice.billing_reason || invoice.billing) {
        case 'subscription_update':
          // Probably triggered by a change in the dashboard... Assume it's a cycle
          // NOTE: fallthrough!
        case 'subscription_cycle':
            // Rata, default case already handled
            baseType = 'Rata mensile';
            break;
        case 'subscription_create':
            // PagoSubito (online)
            baseType = 'Abbonamento';
            break;
        case 'refund_unsolved':
            baseType = 'Spese di insoluto';
            break;
        case 'amnesty':
            baseType = 'Sanatoria';
            break;
        case 'advance_balance':
            baseType = 'Saldo Anticipato';
            break;
        case 'pagodil':
            baseType = 'Abbonamento';
            break;
        case 'bank_transfer':
            // Special case that should basically never happen. Only used for subscription transfer.
            baseType = 'Abbonamento';
            break;
        case 'multiclub_access':
            baseType = 'Opzione Multiclub';
            break;
        case 'manual':
        case 'subscription_threshold':
        case 'upcoming':
        case 'charge_automatically':
        case 'pos':
        default:
            if (invoice.metadata && invoice.metadata.type) {
              // We set metadata.type for products
              switch (invoice.metadata.type) {
                  case 'badge':
                      baseType = 'Badge';
                      break;
                  case 'shower':
                      baseType = 'Docce';
                      break;
                  case 'associative_quote':
                      // For PagoDIL we have an invoice with only the associative quote
                      baseType = '';
                      break;
                  default:
                      baseType = invoice.billing === 'pos' ? 'Abbonamento' : 'Pagamento';
              }
            } else {
              // It's probably a Stripe monthly charge (before we started saving the billing_reason attribute),
              // otherwise fallback to a generic "Pagamento"
              baseType = invoice.billing === 'charge_automatically' ? 'Rata mensile' : 'Pagamento';
            }
    }
      // Try to check if this invoice contains an associative price or not

      let invoiceType = this.hasQuota(invoice) ? (baseType ? baseType + ' + ' : '')  + 'Quota Associativa' : baseType;
      if (invoice.unsolved_object) {
        invoiceType = invoiceType ? invoiceType + ' + ' + 'Spese insoluto' : 'Spese insoluto';
      }
      return invoiceType || 'Pagamento';
  }

  hasQuota(invoice:InvoiceModel) {
    const lines = invoice.strp_lines || [];
    for (const line of lines) {
      if (line['description'].match(/quota associativa iscrizione/i)) {
          return true;
      }
    }
    return false;
  }

  //#endregion invoices

  //#region misc
  public redirectTo(view: string) {
    this.router.navigate([view]);
  }
  //#endregion misc
}
